import React, { useState } from 'react'

export default function AddList({setList, setShowModal}) {

    const [title, setTitle] = useState("")
    const [date, setDate] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        const submitedList = {
            title,
            StartDate: date,
            id: Date.now()
        }
        
        setList(prevState => [...prevState, submitedList])
        setShowModal(false)   //aida realode dai na 
    }

    return (
        <form onSubmit={handleSubmit}>
            <label>
                <span>Coustomer name </span>
                <input onChange={e => setTitle(e.target.value)} type="text" placeholder="write name ......." />
            </label>
            <label>
                <span>order</span>
                <input  onChange={e => setDate(e.target.value)} type="text" placeholder="Dises name ......."/>
            </label>
            <button>Submit</button>
        </form>
    )
}
